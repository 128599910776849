import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoadingInterceptor } from './http-loading.interceptor';
import { SettingsInterceptor } from '@core/interceptor/settings.interceptor';
import { Provider } from '@angular/core';
import { AuthInterceptor } from '@core/interceptor/auth.interceptor';

export * from './http-loading.interceptor';

export * from './settings.interceptor';

export const httpInterceptorProviders: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpLoadingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: SettingsInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
];
