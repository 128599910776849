import { ErrorHandler, Provider } from '@angular/core';
import { GlobalErrorHandler } from './global-error.handler';

export * from './global-error.handler';

export const globalErrorHandlerProviders: Provider[] = [
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  },
];
