import { Injectable } from '@angular/core';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

const PUBLIC_OPERATIONS = ['Industries', 'StartRegistrationFlow'];

@Injectable()
export class AuthInterceptor extends AuthHttpInterceptor {
  override intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      req.body instanceof Object &&
      'operationName' in req.body &&
      PUBLIC_OPERATIONS.includes(req.body.operationName as string)
    ) {
      return next.handle(req);
    }
    return super.intercept(req, next);
  }
}
