import { Routes } from '@angular/router';
import { authGuard } from '@core/guards';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./routes/public/public.routes').then(m => m.routes),
  },
  {
    path: 'manage',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./routes/management/management.routes').then(m => m.routes),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./routes/error/error.routes').then(m => m.routes),
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];
