import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { ErrorDialogService } from '@shared/services';
import { ApolloError } from '@apollo/client/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private _lastErrorHash: string | null = null;

  constructor(
    private readonly _errorDialogService: ErrorDialogService,
    private readonly _zone: NgZone,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}
  handleError(error: Error): void {
    if (!(error instanceof ApolloError)) {
      const errorHash = this._getErrorHash(error);

      if (this._lastErrorHash !== errorHash) {
        this._lastErrorHash = errorHash;
        this._zone.run(() => {
          console.error(error);
          this._errorDialogService.openDialog({
            type: 'Application',
            error,
            location: this._document.location.href,
          });
        });
      }
    }
  }

  private _getErrorHash(error: Error): string {
    return `${error.message}-${error.stack}`;
  }
}
